import PropTypes from 'prop-types';
import getPostTypeStaticProps from '@/functions/wp/posts/getPostTypeStaticProps';
import Page from './[...slug]';

const postType = 'page';
/**
 * Render Home Page
 *
 * @param  {object}  props      Component props object
 * @param  {object}  props.post Post data from WP
 * @return {Element}            Home component
 */
export default function HomePage({ post }) {
  const { ...postData } = post;

  if (postData && Object.keys(postData).length > 0) {
    return <Page post={post} />;
  }

  // Fallback
  return <div>Cannot find homepage data</div>;
}

/**
 * Get post static props
 *
 * @return {object} post props
 */
export async function getStaticProps() {
  // console.log(await getPostTypeStaticProps({ slug: '/' }, postType))
  let data = await getPostTypeStaticProps({ slug: '/' }, postType);
  // console.log(data)
  return data;
}

HomePage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  post: PropTypes.object.isRequired,
};
